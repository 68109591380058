﻿define("controllers/root/home/index",
    ["jquery", "bootstrap", "pinger", "header"],
    function ($, bootstrap, pinger, header) {
        var indexScript = function () {
            this.init();
        };

        indexScript.prototype.init = function () {
        };

        $(function () {
            pinger.init();
            header.init();
        });
    });